<template>
  <div class="editNotice">
    <el-form class="mt10" label-width="160px" label-suffix="：">
      <el-form-item label="名称">
        <el-input
          maxlength="10"
          v-model="form.title"
          style="width: 300px"
          placeholder="请输入名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="归属分公司">
        <el-select v-model="form.spreadId" placeholder="请选择分公司" clearable filterable remote
            :loading="loading" :remote-method="searchBranchOffice" :disabled="disabled"
            @focus="searchBranchOffice('')">
            <el-option v-for="item in branchOfficeList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="目标角色">
        <el-select v-model="form.targetRole" :disabled="disabled">
          <el-option
            v-for="item in roleOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="展示位置">
        <el-select v-model="form.showType">
          <el-option
            v-for="item in positionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="有效日期">
        <el-date-picker
          v-model="time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss">
      </el-date-picker>
      </el-form-item>
      <el-form-item label="內容编辑">
        <TEditor class="editor" v-model="form.content" v-if="flag"/>
      </el-form-item>
      <el-form-item>
        <div class="mt20 center">
          <el-button type="primary" class="cancleBtn" @click="goBack">取消</el-button>
          <el-button type="primary" @click="saveAgent">提交保存</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { $sp_spreadList } from '@/api/order';
import { $sg_noticeDetail, $sp_noticeAdd, $sp_noticeUpdate } from '@/api/notice';

import TEditor from '@/components/common/TEditor.vue';

export default {
  components: {
    TEditor,
  },
  data() {
    return {
      form: {
        title: '',
        spreadId: '',
        targetRole: 0,
        showType: 0,
        content: '',
      },
      time: '',
      roleOptions: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '分公司',
        },
        {
          value: 2,
          label: '代理商',
        },
        {
          value: 3,
          label: '商户',
        },
      ],
      positionOptions: [
        {
          value: 0,
          label: '首页',
        },
        {
          value: 1,
          label: '其他',
        },
      ],
      disabled: false,
      branchOfficeList: [],
      loading: false,
      flag: false,
    };
  },
  created() {
    const code = this.$route.query.code;
    if (code) {
      // 如果存在code为编辑公告，否则为添加公告
      this.disabled = true;
      this.getNoticeDetail(code);
    } else {
      this.flag = true;
    }
  },
  methods: {
    // 请求公告详情
    getNoticeDetail(code) {
      $sg_noticeDetail(code).then((res) => {
        this.form = res;
        this.time = [res.startTime || '', res.expireTime || ''];
        this.branchOfficeList = [
          {
            value: res.spreadId,
            label: res.spreadName,
          },
        ];
        this.flag = true;
      });
    },
    // 搜索归属分公司
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.reviewedShopPage(query);
      } else {
        await this.reviewedShopPage('');
      }
      this.loading = false;
    },
    // 请求归属分公司数据
    reviewedShopPage(platName = '') {
      const params = {
        platName,
      };
      return $sp_spreadList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        this.branchOfficeList = tempArr;
      });
    },
    // 取消
    goBack() {
      this.$router.go('-1');
    },
    // 提交保存
    saveAgent() {
      if (!this.form.title) {
        this.$message.error('请填写名称');
        return;
      }
      // if (!this.form.spreadId) {
      //   this.$message.error('请填写归属分公司');
      //   return;
      // }
      if (!this.time) {
        this.$message.error('请填写有效时间');
        return;
      }
      if (!this.form.content) {
        this.$message.error('请填写内容');
        return;
      }
      const params = {
        ...this.form,
      };
      params.gmtRelease = this.time && this.time.length > 0 ? this.$formatDateByDate(this.time[0], 'yyyy-MM-dd hh:mm:ss') : '';
      params.expireTime = this.time && this.time.length > 0 ? this.$formatDateByDate(this.time[1], 'yyyy-MM-dd hh:mm:ss') : '';
      if (params.code) {
        // 编辑公告
        $sp_noticeUpdate(params).then(() => {
          this.$router.go('-1');
        });
      } else {
        // 添加公告
        $sp_noticeAdd(params).then(() => {
          this.$router.go('-1');
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.editNotice{
  width: 100%;
  min-width: 1400px;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .editor{
    width: 800px;
  }
  .cancleBtn{
    border-color: #4D4D4D;
    background-color: #4D4D4D;
  }
}
</style>
