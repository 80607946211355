<template>
  <div class="noticeList">
    <div class="addNoticeBox">
      <PrevReclick rights="t-system-notice_add">
       <el-button type="primary" @click="goEditNoticePage('')">添加公告</el-button>
      </PrevReclick>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
        <el-table-column prop="title" label="公告名称" show-overflow-tooltip width="220"></el-table-column>
        <el-table-column prop="content" label="内容">
          <template slot-scope="scope">
            <div>{{parseHtml(scope.row.content)}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="staffId" label="操作" width="200">
          <template slot-scope="scope">
            <PrevReclick rights="t-system-notice_update">
              <el-button type="primary" size="small" @click="goEditNoticePage(scope.row.code)">编辑</el-button>
            </PrevReclick>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>
<script>
import { $sp_noticeList } from '@/api/notice';

export default {
  data() {
    return {
      totalPage: 1,
      currentPage: 1,
      tableData: [],
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    // 跳转到编辑/添加公告页面
    goEditNoticePage(code) {
      this.$router.push({
        path: 'editNotice',
        query: {
          code,
        },
      });
    },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 20,
      };
      $sp_noticeList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 提取富文本中的文本内容
    parseHtml(htmlStr) {
      const temp = document.createElement('div');
      temp.innerHTML = htmlStr;
      const output = temp.innerText.slice(0, 200) || temp.textContent;
      return output;
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.noticeList{
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .addNoticeBox{
    width: 100%;
    text-align: right;
  }
  .tableBox{
    width: 100%;
    margin-top: 40px;
  }
  .el-table{
    min-height: 580px;
    & > th {
      background-color: #EF3F46;
      color: #fff;
    }
  }
}
</style>
