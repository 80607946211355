import api from './api';

// 获取订单列表
export const $sg_getOrderList = (params) => api.get('/order/v1/order/getOrderList', params);
// 获取退款列表分页数据
export const $sg_getApplyRefundList = (params) => api.get('/order/v1/order/getApplyRefundList', params);
// 获取店铺基本信息
export const $sg_getShopInfo = () => api.get('/mall/v1/ShopInfo/getShopInfo');
// 获取店铺打印设置
export const $sg_checkMobilePrint = () => api.get('/mall/v1/ShopManager/checkMobilePrint');
// 获取订单列表分类统计
export const $sg_getShopOrderCount = (params) => api.get('/order/v1/order/getShopOrderCount', params);
/**
 * @api {post} /mall/v1/Shop/getBindShopList 获取绑定子店铺列表
 * @apiGroup Shop
 * @apiVersion 0.0.0
 * @apiHeader (请求头) {String} authorization 登录成功返回的token信息
 * @apiParam {int} [shop_id] 店铺id
 * @apiSuccessExample 成功示例:
 *  {
 *      "code": 1000,
 *      "msg": "操作成功",
 *      "data": [],
 *  }
 */
export const $sg_getBindShopList = (params) => api.get('/mall/v1/Shop/getBindShopList', params);
// 买单记录看板统计接口
export const $sp_getPayTheBillStatistics = (params) => api.get('/order/v1/order/getPayTheBillStatistics', params);
// 到店买单订单列表
export const $sg_listOrderConsumes = (params) => api.get('/order/v1/order/listOrderConsumes', params);
// 退款通用接口
export const $sp_refundOrder = (params) => api.post('/platform/v3/RefundOrder/refundOrder', params);
// 撤销订单
export const $sp_cancelOrder = (params) => api.post(`${process.env.VUE_APP_O2O_API_DOMAIN}/order/v2/OrderEvent/cancelOrder`, params);
// 商家自配送将订单改为配送中状态
export const $sp_setOrderDistribution = (params) => api.post('/order/v1/OrderEvent/setOrderDistribution', params);
// 仅外卖商城订单，手动将订单发快跑者
export const $sp_sendOrderToKeloop = (params) => api.postNoReject('/order/v1/OrderEvent/sendOrderToKeloop', params);
// 受理订单
export const $sp_acceptOrder = (params) => api.post('/order/v1/OrderEvent/acceptOrder', params);
// 打印订单
export const $sp_printOrder = (params) => api.post('/shop/v1/Order/printOrder', params);
// 获取平台配置
export const $sp_partialOrder = (params) => api.post('/account/v1/Refund/partialOrder', params);
// 店铺获取余额  params order_id 订单ID
export const $sp_addThirdorder = (params) => api.post('/deliver/v1/Order/addThirdorder', params);
// 验证是否能发往第三方配送  params order_id 订单ID
export const $sp_checkThirdOrder = (params) => api.post('/deliver/v1/order/checkThirdOrder', params);
//
export const $sg_orderInfo = (params) => api.get('/deliver/v1/Order/orderInfo', params);
// 获取商家对商品的权限配置和营销配置
export const $sgw_getShopAuthorityV2 = () => api.getNoMessage('/platform/v2/SpreadConfig/getShopAuthorityV2');
// 驳回申请
export const $sp_shopRefund = (params) => api.post('/order/v3/OrderEvent/shopRefund', params);
// 获取某订单平台的抽成明细和店铺收益明细
export const $sg_getFinanceCommission = (params) => api.get('/finance/v1/Finance/getFinanceCommission', params);
// {get} /order/v2/order/getOrderListV2 历史订单列表
export const $sp_getOrderListV2 = (params) => api.get('/order/v2/order/getOrderListV2', params);

// 代理商订单
export const $sg_findAgentOrderDTO = (params) => api.post('/j/agent/order/findAgentOrderDTO', params);


// 查询外卖订单列表
export const $sg_findTakeAwayOrder = (params) => api.get('/j/platform/t-order/page', params);

// 查询外卖订单汇总
export const $sg_orderStatic = (params) => api.get('/j/platform/t-order/statics', params);

// 查询到店订单列表
export const $sp_findEatInOrder = (params) => api.postNoMessage('/j/platform/t-scan-order/list', params);

// 查询到店订单汇总
export const $sg_scanOrderStatic = (params) => api.postNoMessage('/j/platform/t-scan-order/statics', params);

// 查询商户列表
export const $sp_shopInfoList = (params) => api.postNoMessage('/j/platform/t-shop-info/query/list', params);

// 查询代理商列表
export const $sp_agentList = (params) => api.postNoMessage('/j/platform/t-agent/query/list', params);

// 查询归属分公司列表
export const $sp_spreadList = (params) => api.postNoMessage('/j/platform/t-spread-info/query/list', params);

// // 查询商户列表
// export const $sp_shopInfoList = (params) => api.postNoMessage('/j/platform/t-shop-info/query/list', params);

// // 查询代理商列表
// export const $sp_agentList = (params) => api.postNoMessage('/j/platform/t-agent/query/list', params);

// // 查询归属分公司列表
// export const $sp_spreadList = (params) => api.postNoMessage('/j/platform/t-spread-info/query/list', params);


// 团购订单列表 /j/platform/mchActiveGroupOrder/getOrderList
export const $sg_getGroupOrderList = (params) => api.get('/j/platform/mchActiveGroupOrder/getOrderList', params);

// 团购订单统计 /j/platform/mchActiveGroupOrder/getGroupBuyAmount
export const $sg_getGroupBuyAmount = (params) => api.get('/j/platform/mchActiveGroupOrder/getGroupBuyAmount', params);

// 总平台-话费订单统计数据
export const $sg_getRebateStatistics = (params) => api.get(`${process.env.VUE_APP_O2O_API_DOMAIN}/api/user/biz/chargeOrder/platfrom/count`,
  params);

// 总平台-话费订单分页
export const $sg_getRebateOrderList = (params) => api.get('/api/user/biz/chargeOrder/platfrom/page', params);

// 总平台-手动通过
export const $sg_passRebateOrder = (params) => api.post(`/api/user/biz/chargeOrder/platfrom/pass?orderId=${params}`, params);
// 花费退款接口 /api/user/biz/chargeOrder/platfrom/refund/pass
export const $sg_passRefuse = (params) => api.post(`/api/user/biz/chargeOrder/platfrom/refund/pass?orderId=${params}`, params);
// 联联订单退款申请审核
// export const $sp_refundtheaudit = (params) => api.post('/sssk/api/platform/biz/ll/order/auditRefund', params);
// 联联订单退款
export const $sp_refund = (params) => api.post(`/sssk/api/platform/biz/ll/order/refund?orderNo=${params}`, params);
// 联联订单退款驳回
export const $sp_refundRejected = (params) => api.post('/sssk/api/platform/biz/ll/order/refundRejected', params);
// 联联订单退款申请接口查询
export const $sp_queryRefundList = (params) => api.get('/sssk/api/platform/biz/ll/order/queryRefundList', params);
// 联联订单接口查询
export const $sp_queryList = (params) => api.get('/sssk/api/platform/biz/ll/order/queryList', params);

// 查询跑腿订单列表统计详情
export const $sg_getRunErrands = (params) => api.get('/j/distribution/agent/platfrom/order/runErrands/count', params);

// 跑腿订单列表分页查询
export const $sg_getRunErrandsList = (params) => api.get('/j/distribution/agent/platfrom/order/runErrands/page', params);

// 跑腿订单详情
export const $sg_getRunErrandsDetail = (params) => api.get('/j/distribution/agent/platfrom/order/runErrands/detail', params);

// 导出跑腿订单
export const $sg_getRunErrandsExport = (params) => api.post('/j/distribution/agent/platfrom/order/runErrands/export', params);

// 查询询价渠道
export const $sg_getEnquiryChannel = (params) => api.get('/api/agent/biz/agent/dim/agentV2sByKey/v2', params);
// 获取松鼠团购订单
export const $sg_getSsGroupOrderList = (params) => api.get('/sssk/api/platform/biz/platform/groupOrder/queryGroupOrderList', params);
// 根据订单号查询可撤销订单
export const $sg_selectRevokeOrder = (params) => api.get('/j/distribution/agent/platfrom/order/selectOrder', params);
// 撤销配送操作
export const $sg_moveRevokeOrder = (params) => api.get('/j/distribution/agent/platfrom/order/moveOrder', params);
// 查询撤销操作记录
export const $sg_selectCancelLog = (params) => api.get('/j/distribution/agent/platfrom/order/selectCancelLog', params);




