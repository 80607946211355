import { render, staticRenderFns } from "./editNotice.vue?vue&type=template&id=35f71384&scoped=true&"
import script from "./editNotice.vue?vue&type=script&lang=js&"
export * from "./editNotice.vue?vue&type=script&lang=js&"
import style0 from "./editNotice.vue?vue&type=style&index=0&id=35f71384&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35f71384",
  null
  
)

export default component.exports