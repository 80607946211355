import api from './api';

// 查看公告列表
export const $sp_noticeList = (params) => api.postNoMessage('/j/platform/t-system-notice/list', params);

// 查看公告详情
export const $sg_noticeDetail = (params) => api.get(`/j/platform/t-system-notice/detail/${params}`);

// 新增公告
export const $sp_noticeAdd = (params) => api.post('/j/platform/t-system-notice/add', params);

// 编辑公告
export const $sp_noticeUpdate = (params) => api.post('/j/platform/t-system-notice/update', params);
