import api from './api';

// 修改广告
export const $sp_updateAd = (params) => api.post('j/platform/t-wx-localad-ad/updateAd', params);

// 创建广告回显
export const $sg_findVoById = (params) => api.get(`/j/platform/t-wx-localad-ad/findVoById?adId=${params}`);

// 广告审核
export const $sp_verifyAd = (params) => api.post('/j/platform/t-wx-localad-ad/update', params);

// 广告列表 /j/platform/t-wx-localad-shop/list
export const $sp_adList = (params) => api.postNoMessage('j/platform/t-wx-localad-shop/list', params);
// 开户详情 /j/platform/t-wx-localad-shop/detail/{id}
export const $sp_accountDetail = (params) => api.getNoparam(`j/platform/t-wx-localad-shop/detail/${params}`);
// 开户审核 /j/platform/t-wx-localad-ad/update
export const $sp_accountUpdate = (params) => api.post('j/platform/t-wx-localad-ad/update', params);
// 广告列表 /j/platform/t-wx-localad-ad/list
export const $sp_adverList = (params) => api.postNoMessage('j/platform/t-wx-localad-ad/list', params);
// 广告详情 /j/platform/t-wx-localad-ad/detail
export const $sp_advderDetail = (params) => api.get('/j/platform/WxLocaladAdData/getAdDataByType', params);
// 新增手机号 /j/platform/t_wx_localad_ad_phone/addPhone
export const $sp_addPhone = (params) => api.post('/j/platform/t_wx_localad_ad_phone/addPhone', params);
// 广告的日更新 /j/WxLocaladAdData/addData
export const $sp_addData = (params) => api.post('j/platform/WxLocaladAdData/addData', params);
// 导出开户 /j/platform/t-wx-localad-shop/list/export
export const $sp_exportExcle = (params) => api.get('/j/platform/t-wx-localad-shop/list/export', params);
// 广告列表导出  /j/platform/t-wx-localad-ad/list/export
export const $sp_exportAd = (params) => api.get('/j/platform/t-wx-localad-ad/list/export', params);
// 审核流程 /j/platform/t-wx-localad-shop/audit/{id}
export const $sp_auditAccount = (params, id) => api.post(`j/platform/t-wx-localad-shop/audit/${id}`, params);
// 删除手机号 /j/platform/t_wx_localad_ad_phone/deletePhone?adId=2&phoneNo=13333333338
export const $sp_deleteTel = (params) => api.getNoMessage('/j/platform/t_wx_localad_ad_phone/deletePhone', params);
// /j/WxLocaladAdPhone/pageShopAdPhone
export const $sp_pageShopAdPhone = (params) => api.get('/j/platform/t_wx_localad_ad_phone/pageShopAdPhone', params);
export const $agent_updateShowType = (param) => api.post('/j/platform/t-wx-localad-ad/updateShowState', param);
